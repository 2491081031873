import React from 'react';
import { Box, Container } from '@mui/material';

export interface BasicFrameProps {
  children: React.ReactNode;
}

const BasicFrame: React.FC<BasicFrameProps> = ({ children }: BasicFrameProps) => {
  return (
    <Container maxWidth={'xl'} sx={{ marginRight: 'auto', marginLeft: 'auto', height: '100%' }}>
      <Box className={'flex flex-col'}>{children}</Box>
    </Container>
  );
};

export default BasicFrame;
