/**
 * Note: I've substituted the **Select** component with the **ToggleButton** components for the selection feature.
 */
import React from 'react';
import { Box, BoxProps, Button, ButtonProps, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';

/**
 * Interface representing properties for a custom grid toolbar component.
 *
 * @interface CustomGridToolbarProps
 *
 * @property {string} description
 * Description or title for the custom grid toolbar.
 *
 * @property {Object[]} valueToggle
 * Array of objects representing toggle options.
 * Each object contains a `value` and a `label` property.
 *
 * @property {string} label
 * Label associated with the custom grid toolbar.
 *
 * @property {string} buttonText
 * Text to be displayed on the button within the toolbar.
 *
 * @property {function} submitHandler
 * Function to be executed on button click.
 *
 * @property {string} selectedValue
 * Currently selected value in the custom grid toolbar.
 *
 * @property {function} handleSelectChange
 * Function to handle the selection change event.
 *
 * @property {number} [selectMinWidth]
 * Minimum width of the select component.
 */
export interface CustomGridToolbarWithToggleProps {
  description: string;
  valueToggle: { value: string; label: string }[];
  label: string;
  buttonText: string;
  submitHandler: () => void;
  selectedValue: string;
  handleSelectChange: (event: React.ChangeEvent<{ value: unknown }> | any) => void;
  buttonProps?: ButtonProps;
  containerProps?: BoxProps;
  buttonLeftIcon?: React.ReactNode;
}

const CustomGridToolbarWithToggle: React.FC<CustomGridToolbarWithToggleProps> = ({
  description,
  valueToggle,
  label,
  buttonText,
  submitHandler,
  selectedValue,
  handleSelectChange,
  buttonProps,
  containerProps,
  buttonLeftIcon
}: CustomGridToolbarWithToggleProps) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '16px',
        borderBottom: '1px solid #e0e0e0',
        marginBottom: '16px',
        ...containerProps?.sx
      }}
      {...containerProps}
    >
      <Typography sx={{ flexGrow: 1, textAlign: 'left' }} variant="body1">
        {description}
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          flex: '0 1 auto',
          alignItems: 'center',
          justifyContent: 'flex-end'
        }}
      >
        <Box sx={{ mr: 2, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
          <Typography sx={{ marginRight: 1, marginLeft: 1, fontSize: '0.875rem' }}>{label}</Typography>
          <ToggleButtonGroup
            value={selectedValue}
            exclusive
            size={'small'}
            onChange={handleSelectChange}
            sx={{ height: 30 }}
          >
            {valueToggle.map((option) => (
              <ToggleButton
                size={'small'}
                key={option.value}
                value={option.value}
                sx={{ fontSize: '0.875rem', padding: '0 8px' }}
              >
                {option.label}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </Box>
        <Button
          startIcon={buttonLeftIcon}
          variant="outlined"
          color="primary"
          size={'small'}
          {...buttonProps}
          onClick={submitHandler}
        >
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};

export default CustomGridToolbarWithToggle;
