import { FormControl, InputLabel, MenuItem, Select, ThemeProvider } from '@mui/material';
import { darkBlueBGTheme } from 'data/theme';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { fetchAndSetCurrentBranch } from 'store/slices/branchSlice';
import { getBranchList } from 'store/slices/usersSlice';

const CurrentBranchSelect = () => {
  const [selectedBranch, setSelectedBranch] = useState('');
  const currentBranch = useSelector((state: RootState) => state.branches.currentBranch);
  const branches = useSelector(getBranchList);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    if (currentBranch) {
      setSelectedBranch(currentBranch.publicId);
    }
  }, [currentBranch]);

  const handleChange = (event: any) => {
    const branchPublicId: string = event.target.value;
    setSelectedBranch(branchPublicId);
    dispatch(fetchAndSetCurrentBranch(branchPublicId));
  };

  return (
    <ThemeProvider theme={darkBlueBGTheme}>
      <FormControl variant="standard" fullWidth>
        <InputLabel id="branch-select-label">Selected Branch</InputLabel>
        <Select
          labelId="branch-select-label"
          id="branch-select"
          value={selectedBranch}
          label="Selected Branch"
          onChange={handleChange}
        >
          {branches?.map((branch) => (
            <MenuItem key={branch.branchId} value={branch.branchId}>
              {branch.branchName}
            </MenuItem>
          )) || (
            <MenuItem>
              <em>None</em>
            </MenuItem>
          )}
        </Select>
      </FormControl>
    </ThemeProvider>
  );
};

export default CurrentBranchSelect;
