import React, { useState } from 'react';
import { Box, BoxProps, InputLabel, InputLabelProps, TextField, TextFieldProps } from '@mui/material';
import { GridColumnHeaderParams } from '@mui/x-data-grid';
import { TimeSelectorType } from '../common';

/**
 * Properties to configure a text input within a header row cell.
 *
 * @property {string} columnHeaderText - Text to display as the column header.
 * @property {string} initialText - Initial text to display within the text input.
 * @property {(newText: string) => void} onTextChange - Callback function to handle text changes.
 * @property {BoxProps} [boxProps] - Optional properties to configure the container box.
 * @property {InputLabelProps} [inputLabelProps] - Optional properties to configure the input label.
 * @property {TextFieldProps} [textFieldProps] - Optional properties to configure the text field.
 * @property {GridColumnHeaderParams} [params] - Optional properties representing grid column header parameters.
 * @property {string} [suffix] - Optional suffix text to display within the input field.
 */
interface TextInputHeaderRowCellProps {
  columnHeaderText: string;
  initialText: string;
  onTextChange: (newText: string) => void;
  boxProps?: BoxProps;
  inputLabelProps?: InputLabelProps;
  textFieldProps?: TextFieldProps;
  params?: GridColumnHeaderParams;
  endAdornmentBoxProps?: BoxProps;
  timeType: TimeSelectorType;
  suffix?: string;
}

/**
 * Properties to configure a text input within a header row cell.
 *
 * @property {string} columnHeaderText - Text to display as the column header.
 * @property {string} initialText - Initial text to display within the text input.
 * @property {(newText: string) => void} onTextChange - Callback function to handle text changes.
 * @property {BoxProps} [boxProps] - Optional properties to configure the container box.
 * @property {InputLabelProps} [inputLabelProps] - Optional properties to configure the input label.
 * @property {TextFieldProps} [textFieldProps] - Optional properties to configure the text field.
 * @property {GridColumnHeaderParams} [params] - Optional properties representing grid column header parameters.
 * @property {string} [suffix] - Optional suffix text to display within the input field.
 */
interface RelayDelayColHeaderProps {
  value: number;
  onValueChange: (newValue: number) => void;
  inputLabelProps?: InputLabelProps;
  textFieldProps?: TextFieldProps;
  params?: GridColumnHeaderParams;
  headerLabel: string;
  endAdornmentBoxProps?: BoxProps;
  timeType: TimeSelectorType;
  suffix?: string;
}

export const RelayDelayColHeader: React.FC<RelayDelayColHeaderProps> = ({
  value,
  onValueChange,
  inputLabelProps,
  headerLabel,
  textFieldProps,
  params,
  endAdornmentBoxProps,
  timeType,
  suffix
}: RelayDelayColHeaderProps): React.ReactElement => {
  const width = params?.colDef.width;
  const [delayValue, setDelayValue] = React.useState(value);
  const [headerText, setHeaderText] = useState(value.toString());

  React.useEffect(() => {
    // If there is an update to the timeType, convert the text to the new timeType
    // This adjusts/converts the time for s/ms accordingly
    if (timeType === 'ms') {
      setHeaderText((delayValue * 1000).toString());
    } else if (timeType === 's') {
      setHeaderText(Math.round(delayValue / 1000).toString());
    }
  }, [delayValue, timeType]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      // We always pass the delay as ms to the backend.
      // This is regardless of the timeType.
      let newText = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      if (newText === '') {
        newText = '0';
      }
      const numericValue = parseInt(newText, 10);
      if (timeType === 'ms') {
        setDelayValue(numericValue);
        onValueChange(numericValue);
      } else if (timeType === 's') {
        setDelayValue(numericValue * 1000);
        onValueChange(numericValue * 1000);
      }
    },
    [onValueChange, timeType]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: width ?? '100%',
        height: '100%',
        padding: '8px'
      }}
    >
      <InputLabel
        htmlFor="custom-column-header"
        shrink={true}
        sx={{
          fontSize: '0.75rem',
          color: 'black',
          marginBottom: '0px'
        }}
        {...inputLabelProps}
      >
        {headerLabel}
      </InputLabel>
      <TextField
        id="custom-column-header"
        value={headerText}
        onChange={handleChange}
        variant="standard"
        size={'small'}
        InputProps={{
          endAdornment: suffix ? (
            <Box component="span" sx={{ fontSize: '0.75rem', color: 'gray', ...endAdornmentBoxProps?.sx }}>
              {suffix}
            </Box>
          ) : null
        }}
        sx={{
          marginTop: '0px',
          fontSize: '0.75rem',
          color: 'gray',
          ...textFieldProps?.sx
        }}
        inputProps={{
          ...textFieldProps?.inputProps,
          style: {
            fontSize: '0.75rem',
            color: 'gray',
            ...textFieldProps
          },
          inputMode: 'numeric',
          pattern: '[0-9]*'
        }}
      />
    </Box>
  );
};

/**
 * A functional component representing a header row cell with a text input field.
 * This component is used to render a header cell in a table where the header
 * text can be modified by the user.
 *
 * The text field will be a number.
 *
 * @param {TextInputHeaderRowCellProps} props - The properties required for this component.
 * @param {object} props.params - Additional parameters, including column definitions.
 * @param {string} props.columnHeaderText - The text to be displayed in the column header.
 * @param {string} props.initialText - The initial text for the text input field.
 * @param {function} props.onTextChange - Callback function to handle text changes.
 * @param {object} [props.boxProps] - Additional properties to be spread into the Box component.
 * @param {object} [props.textFieldProps] - Additional properties to be spread into the TextField component.
 * @param {object} [props.inputLabelProps] - Additional properties to be spread into the InputLabel component.
 * @param {object} [props.endAdornmentBoxProps] - Additional properties to be spread into the Box component for the end adornment.
 * @param {string} [props.suffix] - Optional suffix text to display within the input field.
 *
 * @returns {React.ReactElement} A React element representing the header row cell with text input.
 */
const TextInputHeaderRowCell: React.FC<TextInputHeaderRowCellProps> = ({
  params,
  columnHeaderText,
  initialText,
  onTextChange,
  boxProps,
  textFieldProps,
  inputLabelProps,
  suffix,
  timeType,
  endAdornmentBoxProps
}: TextInputHeaderRowCellProps): React.ReactElement => {
  // Attempt to get width from props
  const width = params?.colDef.width;
  const [headerText, setHeaderText] = useState(initialText);

  React.useEffect(() => {
    // If there is an update to the timeType, convert the text to the new timeType

    // Check if headerText is NaN
    if (isNaN(parseInt(headerText, 10))) {
      setHeaderText('0');
    }
  }, [timeType, headerText]);

  const handleChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      let newText = event.target.value.replace(/\D/g, ''); // Remove non-numeric characters
      if (timeType === 'ms') {
        newText = (parseInt(newText, 10) * 1000).toString();
      } else if (timeType === 's') {
        newText = Math.round(parseInt(newText, 10) / 1000).toString();
      }
      setHeaderText(newText);
      onTextChange(newText);
    },
    [onTextChange, timeType]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: width ?? '100%',
        height: '100%',
        padding: '8px'
      }}
      {...boxProps}
    >
      <InputLabel
        htmlFor="custom-column-header"
        shrink={true}
        sx={{
          fontSize: '0.75rem',
          color: 'black',
          marginBottom: '0px'
        }}
        {...inputLabelProps}
      >
        {columnHeaderText}
      </InputLabel>
      <TextField
        id="custom-column-header"
        value={headerText}
        onChange={handleChange}
        variant="standard"
        size={'small'}
        InputProps={{
          endAdornment: suffix ? (
            <Box
              component="span"
              sx={{ fontSize: '0.75rem', color: 'gray', ...endAdornmentBoxProps?.sx }}
              {...endAdornmentBoxProps}
            >
              {suffix}
            </Box>
          ) : null
        }}
        sx={{
          marginTop: '0px',
          fontSize: '0.75rem',
          color: 'gray',
          ...textFieldProps?.sx
        }}
        inputProps={{
          ...textFieldProps?.inputProps,
          style: {
            fontSize: '0.75rem',
            color: 'gray',
            ...textFieldProps?.inputProps?.style
          },
          inputMode: 'numeric',
          pattern: '[0-9]*'
        }}
        {...textFieldProps}
      />
    </Box>
  );
};

export default TextInputHeaderRowCell;
