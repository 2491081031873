import { Box, CircularProgress, Grid, Typography, useTheme } from '@mui/material';
import Divider from '@mui/material/Divider';
import { Menu, MenuItem, Sidebar, SubMenu } from 'react-pro-sidebar';
import { Link, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import {
  AdaptorIcon,
  AdministratorsIcon,
  AnsweringStationIcon,
  DoorStationIcon,
  EntranceStationIcon,
  GatewaySettingsIcon,
  HomeIcon,
  IntercomsIcon,
  IOSettingsIcon,
  RemoteManagementIcon,
  SettingsIcon,
  SiteInfoOutlineIcon,
  TenantManagementIcon,
  TenantsIcon,
  UnitsIcon
} from 'shared/icons/icons';
import { usePermission } from 'context/PermissionContext';
import { PermissionsContextType } from 'permissions/utils';
import { useTranslation } from 'react-i18next';

function RemoteManagementSideBar(props: any) {
  const siteInfo = useSelector((state: RootState) => state.site.siteInfo);
  const isSiteLoading = useSelector((state: RootState) => state.site.Loading);
  const theme = useTheme();
  const location = useLocation();
  const { isAllowedTo } = usePermission();

  // Guard clause to handle null or loading siteInfo
  if (!siteInfo) {
    return (
      <Box sx={styles.loadingSpinner}>
        <CircularProgress />
      </Box>
    );
  }

  const { t } = useTranslation();
  const sitePublicId = siteInfo.publicId;
  const siteName = siteInfo.siteName;
  const siteAddress = siteInfo.siteAddress;
  const siteId = siteInfo.awsPropertyId;
  const isWizardComplete = siteInfo.isWizardCompleted;
  const canViewUsers = isAllowedTo('user:view', sitePublicId, PermissionsContextType.SITE);

  return (
    <Sidebar
      style={styles.sidebar}
      breakPoint="md"
      backgroundColor={theme.palette.neutral.light}
      collapsed={props.collapsed}
      toggled={props.toggled}
    >
      <Link style={styles.siteLink} to={isWizardComplete ? `/site/${sitePublicId}/siteinfo` : '#'}>
        <Box sx={styles.siteBadge}>
          {isSiteLoading ? (
            <Box sx={styles.loadingSpinner}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <Typography sx={styles.siteName}>{siteName}</Typography>
              <Typography sx={styles.siteAddress}>{siteAddress}</Typography>
              <Typography sx={styles.siteAddress}>Property ID: {siteId}</Typography>
              <Grid container spacing={10} direction="column" justifyContent="center">
                <Grid xs={1} item></Grid>
              </Grid>
            </>
          )}
        </Box>
      </Link>
      <Menu
        menuItemStyles={{
          button: ({ active }) => ({
            backgroundColor: active ? '#c9e7ff' : undefined,
            color: active ? '#0071CE' : undefined,
            '&:hover': {
              backgroundColor: active ? '#c9e7ff' : '#e2f2ff'
            }
          })
        }}
      >
        <MenuItem
          active={location.pathname === `/site/${sitePublicId}/siteinfo`}
          component={<Link to={`/site/${sitePublicId}/siteinfo`} />}
          icon={<SiteInfoOutlineIcon viewBox="0 0 26 26" />}
        >
          <Typography variant="body2">{t('Title_Site_Info')}</Typography>
        </MenuItem>
        <SubMenu label={t('Intercoms')} rootStyles={styles.subMenu} icon={<IntercomsIcon viewBox="0 0 26 26" />}>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/answering-stations`}
            component={<Link to={`/site/${sitePublicId}/answering-stations`} />}
            icon={<AnsweringStationIcon viewBox="0 0 26 26" />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">{t('Answering_Stations')}</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/door-stations`}
            component={<Link to={`/site/${sitePublicId}/door-stations`} />}
            icon={<DoorStationIcon viewBox="0 0 26 26" />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">{t('Door_Stations')}</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/entrance-stations`}
            component={<Link to={`/site/${sitePublicId}/entrance-stations`} />}
            icon={<EntranceStationIcon viewBox="0 0 26 26" />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">{t('Entrance_Stations')}</Typography>
          </MenuItem>
        </SubMenu>
        <SubMenu label={t('Adaptors')} rootStyles={styles.subMenu} icon={<AdaptorIcon viewBox="0 0 26 26" />}>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/gateway-adaptors`}
            component={<Link to={`/site/${sitePublicId}/gateway-adaptors`} />}
            icon={<GatewaySettingsIcon viewBox="0 0 26 26" />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">{t('Gateway_Adaptors')}</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/io-adaptors`}
            component={<Link to={`/site/${sitePublicId}/io-adaptors`} />}
            icon={<IOSettingsIcon viewBox="0 0 26 26" />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">{t('IO_Adaptors')}</Typography>
          </MenuItem>
        </SubMenu>
        <SubMenu
          rootStyles={styles.subMenu}
          label={t('Tenant_Management')}
          icon={<TenantManagementIcon viewBox="0 0 26 26" />}
        >
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/units`}
            component={<Link to={`/site/${sitePublicId}/units`} />}
            icon={<UnitsIcon viewBox="0 0 26 26" />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">{t('Units')}</Typography>
          </MenuItem>
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/tenants`}
            component={<Link to={`/site/${sitePublicId}/tenants`} />}
            icon={<TenantsIcon viewBox="0 0 26 26" />}
            disabled={!isWizardComplete}
          >
            <Typography variant="body2">{t('Tenants')}</Typography>
          </MenuItem>
        </SubMenu>
      </Menu>
      <Menu
        menuItemStyles={{
          button: ({ active }) => ({
            backgroundColor: active ? theme.palette.neutral.medium : undefined
          })
        }}
        rootStyles={styles.secondMenu}
      >
        <Divider />
        <MenuItem
          active={location.pathname === '/'}
          component={<Link to="/" />}
          icon={<HomeIcon viewBox="0 0 26 26" />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            {t('Home')}
          </Typography>
        </MenuItem>
        {canViewUsers && (
          <MenuItem
            active={location.pathname === `/site/${sitePublicId}/users`}
            component={<Link to={`/site/${sitePublicId}/users`} />}
            icon={<AdministratorsIcon viewBox="0 0 26 26" />}
          >
            <Typography variant="body2">{t('Site_Administrators')}</Typography>
          </MenuItem>
        )}
        <MenuItem
          active={location.pathname === `/site/${sitePublicId}/settings`}
          component={<Link to={`/site/${sitePublicId}/settings`} />}
          icon={<SettingsIcon viewBox="0 0 26 26" />}
        >
          <Typography variant="body2">{t('Settings')}</Typography>
        </MenuItem>
        <MenuItem
          active={location.pathname === '/remotemanagement'}
          component={<Link to="/remotemanagement" />}
          icon={<RemoteManagementIcon viewBox="0 0 26 26" />}
        >
          <Typography style={styles.overflowHidden} variant="body2">
            {t('home.tiles.remoteManagement.title', 'Remote Management')}
          </Typography>
        </MenuItem>
      </Menu>
    </Sidebar>
  );
}

/** @type {import('@mui/material'.SxProps)} */
const styles = {
  sidebar: {
    height: 'calc(100%-64px)',
    position: 'sticky',
    top: '0',
    left: '0',
    zIndex: '1'
  },
  siteLink: {
    textDecoration: 'none'
  },
  siteBadge: {
    height: '7rem',
    border: '2px solid #D7D7D7',
    boxShadow: '.5px .5px .5px .5px rgba(0,0,0,0.2)',
    borderRadius: '2%',
    marginX: '.5rem',
    marginY: '.5rem',
    '&:hover': {
      backgroundColor: '#f3f3f3'
    }
  },
  siteName: {
    color: '#003366',
    textDecoration: 'none',
    fontSize: '1.25rem',
    fontWeight: '700',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'block',
    marginY: '.5rem',
    paddingX: '.5rem'
  },
  siteAddress: {
    color: '#003366',
    textDecoration: 'none',
    fontSize: '1rem',
    fontWeight: '400',
    textAlign: 'center',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'wrap',
    display: 'block',
    marginY: '.5rem',
    paddingX: '.5rem'
  },
  progressBar: {
    backgroundColor: '#ffd5b1 ',
    '& .MuiLinearProgress-bar': {
      backgroundColor: '#FF7500'
    },
    padding: '.30rem',
    marginLeft: '.5rem',
    marginRight: '.5rem'
  },
  progressBarText: {
    textAlign: 'center',
    color: '#003366'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  secondMenu: {
    position: 'absolute',
    bottom: '0',
    width: '100%',
    '@media (max-height: 1200px)': {
      position: 'relative'
    }
  },
  loadingSpinner: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 75
  },
  subMenu: {
    '&:hover': {
      backgroundColor: '#e2f2ff'
    },
    '.ps-submenu-content': {
      width: 'auto'
    },
    margin: 0,
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '0.875rem',
    lineHeight: 1.43,
    letterSpacing: '0.01071em'
  }
};

export default RemoteManagementSideBar;
