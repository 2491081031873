import React from 'react';
import { Tab, Tabs, TabsProps, TabProps, Box, BoxProps } from '@mui/material';

/**
 * TabView represents a tab within the Tabs container.
 *
 * @typedef {object} TabView
 *
 * @property {string} label - The label for the tab.
 * @property {React.ReactElement} ChildTabView - A React element representing the content of the tab.

 */
export interface TabView {
  label: string;
  ChildTabView: React.ReactElement;
  description?: string;
  onPressCallback?: () => void;
}

/**
 * Interface representing the properties for TabSwitcherContainer component.
 *
 * @property {TabView[]} tabs - An array of objects representing individual tabs within the TabSwitcherContainer component. Each tab may contain specific content.
 * @property {TabsProps} [tabsProps] - Optional properties to customize the appearance and behavior of the tab container.
 * @property {TabProps} [tabProps] - Optional properties to customize the appearance and behavior of individual tabs.
 * @property {BoxProps} [boxContainerProps] - Optional properties to customize the appearance and behavior of the box container that wraps around the tabs.
 * @property {BoxProps} [boxMainProps] - Optional properties to customize the appearance and behavior of the main box within the TabSwitcherContainer component.
 */
export interface TabSwitcherContainerProps {
  tabs: TabView[];
  tabsProps?: TabsProps;
  tabProps?: TabProps;
  boxContainerProps?: BoxProps;
  boxMainProps?: BoxProps;
  startingTabIndex?: number;
}

/**
 * TabSwitcherContainer is a React functional component that renders a set of tabs along with their content.
 * The tabs allow the user to switch between different sets of content, which are displayed in the ChildTabView of each TabView.
 *
 * @param {TabSwitcherContainerProps} props - The properties to configure the TabSwitcherContainer component.
 * @param {Array} props.tabs - An array of tab definitions, each containing a label and a ChildTabView.
 * @param {Object} [props.tabsProps] - Additional props to pass to the Tabs
 * @param {Object} [props.tabProps] - Additional props to pass to each Tab component.
 * @param {Object} [props.boxContainerProps] - Additional props to pass to the outer Box component.
 * @param {Object} [props.boxMainProps] - Additional props to pass to the main
 * @param {number} [props.startingTabIndex] - The index of the tab to display initially.
 *
 * @returns {React.ReactElement} A React component that renders tabs and their content.
 */
const TabSwitcherContainer: React.FC<TabSwitcherContainerProps> = ({
  startingTabIndex,
  tabs,
  tabsProps,
  tabProps,
  boxContainerProps,
  boxMainProps
}: TabSwitcherContainerProps): React.ReactElement => {
  const [tabIndex, setTabIndex] = React.useState(startingTabIndex ?? 0);

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Box
      {...boxContainerProps}
      sx={{ display: 'flex', flexDirection: 'column', width: '100%', ...boxContainerProps?.sx }}
    >
      <Tabs variant={'fullWidth'} value={tabIndex} onChange={handleTabChange} {...tabsProps}>
        {tabs.map((tab, index) => (
          <Tab key={tab.label + index.toString()} label={tab.label} {...tabProps} />
        ))}
      </Tabs>
      <Box {...boxMainProps}>{tabs[tabIndex].ChildTabView}</Box>
    </Box>
  );
};

export default TabSwitcherContainer;
